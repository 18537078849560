'use strict'

jQuery(document).ready(function () {
  initDrop();
  initSlider();
  initBurger();;
  initAccordeon();
  initTabs();
});

function initDrop() {
  var drop = jQuery('.drop__btn-solo');

  drop.on('click', function () {
    event.preventDefault();
    var allDropBoxes = $('.drop__box-solo');
    var currentDropBox = jQuery(this).closest('.drop__box-solo');

    if (currentDropBox.hasClass('active')) {
      currentDropBox.removeClass('active');
    } else {
      allDropBoxes.removeClass('active');
      currentDropBox.addClass('active');
    }
  });

  jQuery(document).mouseup(function (e) {
    var block = jQuery('.drop__box-solo');
    if (!block.is(e.target)
      && block.has(e.target).length === 0) {
      block.removeClass('active');
    }
  });
}

function initSlider() {
  $('.hero-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    dots: true
  });
}

function initBurger() {
  var menuBtn = jQuery('.main-nav-opener');
  var menu = jQuery('body');

  menuBtn.on('click', function () {
    event.preventDefault();
    menu.toggleClass('nav-active')
  });
}

function initAccordeon() {

  var dropBtn = jQuery('.drop__btn');

  dropBtn.on('click', function () {
    event.preventDefault();
    var link = $(this);
    var closestUl = link.closest('ul');
    var parallelActiveLinks = closestUl.find('.active')
    var closestLi = link.closest('li');
    var linkStatus = closestLi.hasClass('active');
    var count = 0;

    closestUl.find('.drop__menu').slideUp(function () {
      if (++count == closestUl.find('.drop__menu').length)
        parallelActiveLinks.removeClass('active');
    });

    if (!linkStatus) {
      closestLi.children('.drop__menu').slideDown();
      closestLi.addClass('active');
    }
  });
}

function initTabs() {
  $('.catalogue-tabs').tabslet({
    animation: true,
    controls: {
      prev: '.tab-prev',
      next: '.tab-next'
    }
  })
}

